/****************************************/
/******* Styles CSD-PAYSURF 2019 ********/
/****************************************/
/* Description :                        */
/*  - Styles CSD-PAYSURF                */
/* Responsabilité :                     */
/*  - SB - AF       					*/
/* Historique :                         */
/*  - 06/02/2020 : création             */
/****************************************/
/****************************************/
@font-face {
  font-family: "fts_commun";
  src: url("/partage/fr/CSD/styles/kit-ei-glyph/fonts/fts_commun.eot");
  src: url("/partage/fr/CSD/styles/kit-ei-glyph/fonts/fts_commun.eot?#iefix") format("eot"), url("/partage/fr/CSD/styles/kit-ei-glyph/fonts/fts_commun.woff2") format("woff2"), url("/partage/fr/CSD/styles/kit-ei-glyph/fonts/fts_commun.woff") format("woff"), url("/partage/fr/CSD/styles/kit-ei-glyph/fonts/fts_commun.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/*==============================================
. Break
============================================== */
/*==============================================
. Fonts
============================================== */
/* Bleu */
/* Bleu différent */
/* Bleu clair */
/* Bleu  */
/* Bleu  */
/* Rouge */
/* Vert  */
/* Jaune --*/
/* Rouge fluo --*/
/* Blanc */
/* Noir */
/* Gris très très clair */
/* Gris très clair */
/* Gris clair */
/* Gris */
/* Gris foncé */
/* Gris noir */
/* IE10 + IE11 */
.cc.main .body code.insert {
  display: block;
  background: #222222 !important;
  color: #fff !important;
  border-left: 0 !important;
  white-space: pre;
  line-height: 1;
  overflow: scroll;
  /*px*/ margin: 20px 0 20px;
  /*rem*/ margin: 20 / 16rem 0 / 16rem 20 / 16rem;
  /*px*/ padding: 5px 25px 15px;
  /*rem*/ padding: 5 / 16rem 25 / 16rem 15 / 16rem;
  /*px*/ font-size: 14px;
  /*rem*/ font-size: 14 / 16rem;
}
.cc.main .body code.insert .tag {
  color: #f92c47;
}
.cc.main .body code.insert .property {
  color: #83e22e;
}
.cc.main .body code.insert .value {
  color: #e6db74;
}
.cc.main .body strong.ctxt-featured {
  color: #c31c1c;
  /*px*/ font-size: 18px;
  /*rem*/ font-size: 18 / 16rem;
}
.cc.main .body .disposition .flex > div {
  height: 30px;
  background: #d15462;
  color: #fff;
}
.cc.main .body .disposition .flex > div:nth-child(2n) {
  background: #4471a8;
}
.cc.main .body .more.summary:before {
  font-family: fts_commun;
  content: "\E605";
  padding-right: 5px;
  font-size: 10px;
}
.cc.main .body .more.summary:after {
  content: none;
}
/****************************************/
/********** Styles CNCM 2019 ************/
/****************************************/
/* Description :                        */
/*  - Styles CNCM/Core              	*/
/* Responsabilité :                     */
/*  - SB        						*/
/* Historique :                         */
/*  - 04/04/2019 : création             */
/****************************************/
/****************************************/
html {
  overflow-x: hidden !important;
}
@media only screen and (max-width: 768px) {
  body {
    overflow-x: hidden;
  }
}
.cc {
  color: #414141;
  /*px*/ font-size: 14px;
  /*rem*/ font-size: 14 / 16rem;
}
.cc * {
  box-sizing: border-box;
}
.cc .justify {
  text-align: justify;
}
.cc .svg {
  width: 100%;
}
.cc .mobile.svg {
  padding: 0 !important;
}
.cc .border {
  border: 1px solid #e0e0e0;
}
.cc .mobile {
  display: none;
}
@media only screen and (max-width: 768px) {
  .cc .mobile {
    display: block;
  }
}
@media only screen and (max-width: 768px) {
  .cc .desktop {
    display: none;
  }
}
.cc .noborder,
.cc #noborder {
  border: none !important;
  padding: 0 !important;
}
.cc .background {
  background: #f3f4f4;
}
.cc .soft {
  /*px*/ font-size: 12px;
  /*rem*/ font-size: 12 / 16rem;
  color: #8e9298;
}
.cc .example {
  color: #74787f;
}
.cc .teaser {
  font-style: italic;
  /*px*/ font-size: 16px;
  /*rem*/ font-size: 16 / 16rem;
}
.cc .teaser.no-italic {
  font-style: normal;
}
.cc .bigger {
  /*px*/ font-size: 18px;
  /*rem*/ font-size: 18 / 16rem;
}
.cc .smaller {
  /*px*/ font-size: 12px;
  /*rem*/ font-size: 12 / 16rem;
}
.cc .all-caps,
.cc .uppercase {
  text-transform: uppercase;
}
.cc .italic {
  font-style: italic;
}
.cc .clickable {
  cursor: pointer;
}
.cc .invisible,
.cc .hide,
.cc .hidden {
  position: absolute !important;
  overflow: hidden !important;
  padding: 0 !important;
  border: 0 !important;
  clip: rect(0, 0, 0, 0) !important;
  -webkit-clip-path: circle(0) !important;
  clip-path: circle(0) !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  white-space: nowrap !important;
}
.cc .light {
  font-weight: 300;
}
.cc .regular {
  font-weight: 400;
}
.cc .semibold {
  font-weight: 600;
}
.cc .bold {
  font-weight: 700;
}
.cc .extrabold {
  font-weight: 800;
}
.cc .flex {
  display: flex;
  flex-wrap: wrap;
  clear: both;
}
.cc .flex .center-align {
  align-self: center;
}
.cc .flex .flex10 h1,
.cc .flex .flex15 h1,
.cc .flex .flex20 h1,
.cc .flex .flex25 h1,
.cc .flex .flex30 h1,
.cc .flex .flex33 h1,
.cc .flex .flex40 h1,
.cc .flex .flex50 h1,
.cc .flex .flex60 h1,
.cc .flex .flex66 h1,
.cc .flex .flex70 h1,
.cc .flex .flex80 h1,
.cc .flex .flex90 h1,
.cc .flex .flex10 h2,
.cc .flex .flex15 h2,
.cc .flex .flex20 h2,
.cc .flex .flex25 h2,
.cc .flex .flex30 h2,
.cc .flex .flex33 h2,
.cc .flex .flex40 h2,
.cc .flex .flex50 h2,
.cc .flex .flex60 h2,
.cc .flex .flex66 h2,
.cc .flex .flex70 h2,
.cc .flex .flex80 h2,
.cc .flex .flex90 h2,
.cc .flex .flex10 h3,
.cc .flex .flex15 h3,
.cc .flex .flex20 h3,
.cc .flex .flex25 h3,
.cc .flex .flex30 h3,
.cc .flex .flex33 h3,
.cc .flex .flex40 h3,
.cc .flex .flex50 h3,
.cc .flex .flex60 h3,
.cc .flex .flex66 h3,
.cc .flex .flex70 h3,
.cc .flex .flex80 h3,
.cc .flex .flex90 h3,
.cc .flex .flex10 h4,
.cc .flex .flex15 h4,
.cc .flex .flex20 h4,
.cc .flex .flex25 h4,
.cc .flex .flex30 h4,
.cc .flex .flex33 h4,
.cc .flex .flex40 h4,
.cc .flex .flex50 h4,
.cc .flex .flex60 h4,
.cc .flex .flex66 h4,
.cc .flex .flex70 h4,
.cc .flex .flex80 h4,
.cc .flex .flex90 h4,
.cc .flex .flex10 h5,
.cc .flex .flex15 h5,
.cc .flex .flex20 h5,
.cc .flex .flex25 h5,
.cc .flex .flex30 h5,
.cc .flex .flex33 h5,
.cc .flex .flex40 h5,
.cc .flex .flex50 h5,
.cc .flex .flex60 h5,
.cc .flex .flex66 h5,
.cc .flex .flex70 h5,
.cc .flex .flex80 h5,
.cc .flex .flex90 h5,
.cc .flex .flex10 h6,
.cc .flex .flex15 h6,
.cc .flex .flex20 h6,
.cc .flex .flex25 h6,
.cc .flex .flex30 h6,
.cc .flex .flex33 h6,
.cc .flex .flex40 h6,
.cc .flex .flex50 h6,
.cc .flex .flex60 h6,
.cc .flex .flex66 h6,
.cc .flex .flex70 h6,
.cc .flex .flex80 h6,
.cc .flex .flex90 h6 {
  margin: 0;
  align-self: center;
}
.cc .flex .flex10 p,
.cc .flex .flex15 p,
.cc .flex .flex20 p,
.cc .flex .flex25 p,
.cc .flex .flex30 p,
.cc .flex .flex33 p,
.cc .flex .flex40 p,
.cc .flex .flex50 p,
.cc .flex .flex60 p,
.cc .flex .flex66 p,
.cc .flex .flex70 p,
.cc .flex .flex80 p,
.cc .flex .flex90 p {
  /*px*/ margin: 0 0 15px;
  /*rem*/ margin: 0 / 16rem 0 / 16rem 15 / 16rem;
}
.cc .flex .flex10 p:last-child,
.cc .flex .flex15 p:last-child,
.cc .flex .flex20 p:last-child,
.cc .flex .flex25 p:last-child,
.cc .flex .flex30 p:last-child,
.cc .flex .flex33 p:last-child,
.cc .flex .flex40 p:last-child,
.cc .flex .flex50 p:last-child,
.cc .flex .flex60 p:last-child,
.cc .flex .flex66 p:last-child,
.cc .flex .flex70 p:last-child,
.cc .flex .flex80 p:last-child,
.cc .flex .flex90 p:last-child {
  margin: 0;
}
.cc .flex .flex10 {
  width: 10% !important;
}
.cc .flex .flex15 {
  width: 15% !important;
}
.cc .flex .flex20 {
  width: 20% !important;
}
.cc .flex .flex25 {
  width: 25% !important;
}
.cc .flex .flex30 {
  width: 30% !important;
}
.cc .flex .flex33 {
  width: 33.3% !important;
}
.cc .flex .flex40 {
  width: 40% !important;
}
.cc .flex .flex50 {
  width: 50% !important;
}
.cc .flex .flex60 {
  width: 60% !important;
}
.cc .flex .flex66 {
  width: 66% !important;
}
.cc .flex .flex70 {
  width: 70% !important;
}
.cc .flex .flex80 {
  width: 80% !important;
}
.cc .flex .flex90 {
  width: 90% !important;
}
.cc .flex .flex100 {
  width: 100% !important;
}
.cc .flex-no-wrap {
  --auto-grid-min-size: 10rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
  grid-gap: 1rem;
}
.cc .w-full {
  width: 100%;
}
@media only screen and (max-width: 970px) {
  .cc.main .flex {
    display: block;
    /*px*/ margin-bottom: 15px;
    /*rem*/ margin-bottom: 15 / 16rem;
  }
  .cc.main .flex .flex10,
  .cc.main .flex .flex15,
  .cc.main .flex .flex20,
  .cc.main .flex .flex25,
  .cc.main .flex .flex30,
  .cc.main .flex .flex33,
  .cc.main .flex .flex40,
  .cc.main .flex .flex50,
  .cc.main .flex .flex60,
  .cc.main .flex .flex66,
  .cc.main .flex .flex70,
  .cc.main .flex .flex80,
  .cc.main .flex .flex90 {
    width: 100% !important;
  }
}
.cc .inline {
  display: inline;
}
.cc .block {
  display: block;
}
.cc .inline-block {
  display: inline-block;
}
.cc ul.inline li {
  display: inline;
}
.cc ul.block li {
  display: block;
}
.cc ul.inline-block li {
  display: inline-block;
  /*px*/ margin-left: 10px;
  /*rem*/ margin-left: 10 / 16rem;
}
.cc ul.inline-block li:first-child {
  margin-left: 0;
}
.cc h1 {
  /*px*/ font-size: 32px;
  /*rem*/ font-size: 32 / 16rem;
  font-weight: 700;
}
@media only screen and (max-width: 768px) {
  .cc h1 {
    /*px*/ font-size: 26px;
    /*rem*/ font-size: 26 / 16rem;
  }
}
.cc h2 {
  /*px*/ font-size: 24px;
  /*rem*/ font-size: 24 / 16rem;
  text-transform: uppercase;
  font-weight: 700;
}
@media only screen and (max-width: 768px) {
  .cc h2 {
    /*px*/ font-size: 20px;
    /*rem*/ font-size: 20 / 16rem;
  }
}
.cc h3 {
  /*px*/ font-size: 18px;
  /*rem*/ font-size: 18 / 16rem;
  text-transform: uppercase;
  font-weight: 700;
}
@media only screen and (max-width: 768px) {
  .cc h3 {
    /*px*/ font-size: 16px;
    /*rem*/ font-size: 16 / 16rem;
  }
}
.cc p {
  /*px*/ font-size: 14px;
  /*rem*/ font-size: 14 / 16rem;
  /*px*/ line-height: 22px;
  /*rem*/ line-height: 22 / 16rem;
  /*px*/ margin-bottom: 15px;
  /*rem*/ margin-bottom: 15 / 16rem;
  color: #414141;
}
.cc .center {
  text-align: center !important;
}
.cc .center p {
  text-align: center !important;
}
.cc .left {
  text-align: left !important;
}
.cc .left p {
  text-align: left !important;
}
.cc .right {
  text-align: right !important;
}
.cc .right p {
  text-align: right !important;
}
.cc .float-right {
  float: right;
}
@media only screen and (max-width: 970px) {
  .cc .float-right {
    float: none;
  }
}
.cc .float-left {
  float: left;
}
@media only screen and (max-width: 970px) {
  .cc .float-left {
    float: none;
  }
}
.cc .clear {
  clear: both;
}
.cc .clearR {
  clear: right;
}
.cc .clearL {
  clear: left;
}
.cc .margins {
  /*px*/ margin: 15px;
  /*rem*/ margin: 15 / 16rem;
}
.cc .paddings {
  /*px*/ padding: 15px;
  /*rem*/ padding: 15 / 16rem;
}
.cc .nomargin {
  margin: 0 !important;
}
.cc .nopadding {
  margin: 0 !important;
}
.cc .padding-top {
  /*px*/ padding-top: 15px;
  /*rem*/ padding-top: 15 / 16rem;
}
.cc .padding-right {
  /*px*/ padding-right: 15px;
  /*rem*/ padding-right: 15 / 16rem;
}
.cc .padding-left {
  /*px*/ padding-left: 15px;
  /*rem*/ padding-left: 15 / 16rem;
}
.cc .padding-bottom {
  /*px*/ padding-bottom: 15px;
  /*rem*/ padding-bottom: 15 / 16rem;
}
.cc .margin-top {
  /*px*/ margin-top: 15px;
  /*rem*/ margin-top: 15 / 16rem;
}
.cc .margin-right {
  /*px*/ margin-right: 15px;
  /*rem*/ margin-right: 15 / 16rem;
}
.cc .margin-left {
  /*px*/ margin-left: 15px;
  /*rem*/ margin-left: 15 / 16rem;
}
.cc .margin-bottom {
  /*px*/ margin-bottom: 15px;
  /*rem*/ margin-bottom: 15 / 16rem;
}
.cc .button {
  background: #273a7e;
  color: #fff;
  /*px*/ padding: 10px 20px;
  /*rem*/ padding: 10 / 16rem 20 / 16rem;
  text-transform: uppercase;
  display: inline-block;
}
.cc .button:hover {
  background: #3e5dc9;
  color: #fff;
}
.cc .button:focus {
  background: #3e5dc9;
  color: #fff;
}
.cc .button.more {
  /*px*/ padding: 10px 40px 10px 20px;
  /*rem*/ padding: 10 / 16rem 40 / 16rem 10 / 16rem 20 / 16rem;
}
.cc .button.more:after {
  font-family: fts_commun;
  content: "\E636";
  /*px*/ font-size: 11px;
  /*rem*/ font-size: 11 / 16rem;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  /*px*/ padding-left: 10px;
  /*rem*/ padding-left: 10 / 16rem;
}
.cc .button.more:before {
  content: none;
}
.cc .button.more:hover,
.cc .button.more:focus {
  color: #fff;
}
@media only screen and (max-width: 768px) {
  .cc .button.more {
    color: black;
  }
}
.cc .more {
  font-weight: 600;
  position: relative;
  /*px*/ padding-left: 20px;
  /*rem*/ padding-left: 20 / 16rem;
}
.cc .more:before {
  font-family: fts_commun;
  content: "\E663";
  color: #ba0020;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  /*px*/ padding-right: 5px;
  /*rem*/ padding-right: 5 / 16rem;
}
.cc .more:hover,
.cc .more:focus {
  color: #1683b5;
}
.cc ul .button,
.cc ol .button {
  /*px*/ padding: 10px 20px;
  /*rem*/ padding: 10 / 16rem 20 / 16rem;
  /*px*/ margin: 10px;
  /*rem*/ margin: 10 / 16rem;
}
.cc .actions {
  /*px*/ margin-top: 20px;
  /*rem*/ margin-top: 20 / 16rem;
}
.cc a {
  -webkit-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
  color: #1683b5;
}
.cc a:hover {
  color: #273a7e;
}
.cc a:focus {
  color: #0d445f;
}
.cc img {
  width: 100%;
}
.cc figure {
  text-align: center;
}
@media only screen and (max-width: 768px) {
  .cc figure {
    margin: 0;
  }
}
.cc figure figcaption {
  /*px*/ margin-top: 10px;
  /*rem*/ margin-top: 10 / 16rem;
  font-style: italic;
  /*px*/ font-size: 12px;
  /*rem*/ font-size: 12 / 16rem;
}
.cc figure.smallimg {
  display: table;
  /*px*/ margin: 30px auto;
  /*rem*/ margin: 30 / 16rem auto;
  width: 300px;
}
.cc figure.smallimg img {
  margin: 0 auto;
  display: block;
  max-width: none;
}
@media only screen and (max-width: 970px) {
  .cc figure.smallimg img {
    max-width: 100%;
  }
}
.cc iframe {
  width: 100%;
  min-height: 400px;
}
.cc iframe[frameborder="0"] {
  min-height: 480px;
}
@media only screen and (max-width: 768px) {
  .cc iframe[frameborder="0"] {
    min-height: 250px;
  }
}
.cc ul,
.cc ol {
  /*px*/ margin: 20px 0;
  /*rem*/ margin: 20 / 16rem 0 / 16rem;
  padding: 0;
}
.cc ul li,
.cc ol li {
  /*px*/ margin-bottom: 10px;
  /*rem*/ margin-bottom: 10 / 16rem;
}
.cc ul li strong.bigger,
.cc ol li strong.bigger {
  /*px*/ font-size: 36px;
  /*rem*/ font-size: 36 / 16rem;
}
.cc ul.styled,
.cc ol.styled {
  /*px*/ padding-left: 45px;
  /*rem*/ padding-left: 45 / 16rem;
  list-style: none;
}
.cc ul.styled > li ul li,
.cc ol.styled > li ul li {
  list-style: none;
}
.cc ul.styled > li ul li:before,
.cc ol.styled > li ul li:before {
  font-family: 'fts_commun';
  content: "\2022";
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  position: relative;
  /*px*/ left: 0;
  /*rem*/ left: 0 / 16rem;
  vertical-align: initial;
}
.cc ul ul,
.cc ol ul,
.cc ul ol,
.cc ol ol {
  /*px*/ margin: 10px 30px;
  /*rem*/ margin: 10 / 16rem 30 / 16rem;
}
.cc ul {
  list-style: none;
}
.cc ul.styled {
  /*px*/ padding-left: 40px;
  /*rem*/ padding-left: 40 / 16rem;
}
.cc ul.styled > li:before {
  font-family: 'fts_commun';
  content: "\EAAA";
  color: #4869d3;
  font-size: 20px;
  display: inline-block;
  margin-left: -1em;
  vertical-align: middle;
  position: relative;
  /*px*/ left: -8px;
  /*rem*/ left: -8 / 16rem;
}
.cc ol {
  list-style-position: inside;
}
.cc ol.styled {
  /*px*/ padding-left: 40px;
  /*rem*/ padding-left: 40 / 16rem;
  counter-reset: li;
}
.cc ol.styled > li {
  position: relative;
  /*px*/ margin-bottom: 16px;
  /*rem*/ margin-bottom: 16 / 16rem;
  /*px*/ padding-left: 5px;
  /*rem*/ padding-left: 5 / 16rem;
  counter-increment: li;
}
.cc ol.styled > li:before {
  display: inline-block;
  text-align: right;
  color: #4869d3;
  /*px*/ font-size: 22px;
  /*rem*/ font-size: 22 / 16rem;
  content: counter(li);
  margin-left: -1.5em;
  margin-right: 0.5em;
  direction: rtl;
}
.cc ol.styled > li ul.styled {
  padding-left: 0;
}
.cc table {
  width: 100%;
}
.cc table caption {
  background: #0d445f;
  /*px*/ padding: 10px 20px;
  /*rem*/ padding: 10 / 16rem 20 / 16rem;
  color: #fff;
}
.cc table thead th {
  /*px*/ font-size: 13px;
  /*rem*/ font-size: 13 / 16rem;
  text-align: left;
  /*px*/ padding: 15px;
  /*rem*/ padding: 15 / 16rem;
  background-color: #273a7e;
  color: #fff;
}
.cc table thead th p,
.cc table thead th li {
  /*px*/ font-size: 13px;
  /*rem*/ font-size: 13 / 16rem;
  color: #fff;
}
.cc table tbody td {
  /*px*/ font-size: 13px;
  /*rem*/ font-size: 13 / 16rem;
  /*px*/ padding: 15px;
  /*rem*/ padding: 15 / 16rem;
}
.cc table tbody td.group {
  background: rgba(13, 68, 95, 0.1);
  text-align: center;
  color: #0d445f;
  font-weight: 700;
  /*px*/ font-size: 16px;
  /*rem*/ font-size: 16 / 16rem;
}
.cc table tbody td p,
.cc table tbody td li {
  /*px*/ font-size: 13px;
  /*rem*/ font-size: 13 / 16rem;
}
.cc table tbody td .teaser {
  color: #1683b5;
}
.cc table.border thead th {
  border-right: 1px solid #8e9298;
}
.cc table.border thead th:last-child {
  border: none;
}
.cc table.border tbody td {
  border-right: 1px solid #e0e0e0;
}
.cc table.border tbody td:last-child {
  border: none;
}
.cc table.striped tbody tr:nth-child(even) {
  background: #e0e0e0;
}
.cc table.two-entry thead td {
  background-color: #273a7e;
  color: #fff;
}
.cc table.two-entry tbody th[scope="row"] {
  background-color: #273a7e;
  color: #fff;
}
.cc table.two-entry.border thead td {
  border-right: 1px solid #e0e0e0;
}
.cc table.two-entry.border tbody th[scope="row"] {
  border-top: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}
.cc table.two-entry.border tbody td {
  border-bottom: 1px solid #e0e0e0;
}
.cc table.one-entry.border tbody th[scope="row"] {
  border-right: 1px solid #e0e0e0;
  width: 40%;
  text-align: right;
  /*px*/ padding-right: 20px;
  /*rem*/ padding-right: 20 / 16rem;
}
@media only screen and (max-width: 970px) {
  .cc table.RWD-M {
    display: none;
  }
}
.cc table.RWD-M-alt {
  display: none;
}
@media only screen and (max-width: 970px) {
  .cc table.RWD-M-alt {
    display: block;
  }
}
.cc table.RWD-M-alt caption,
.cc table.RWD-M-alt tbody,
.cc table.RWD-M-alt tr,
.cc table.RWD-M-alt th,
.cc table.RWD-M-alt td {
  width: 100%;
  display: block;
  text-align: center;
}
.cc table.RWD-M-alt tbody td {
  /*px*/ padding: 10px;
  /*rem*/ padding: 10 / 16rem;
}
.cc table.RWD-M-alt caption {
  /*px*/ margin-bottom: 15px;
  /*rem*/ margin-bottom: 15 / 16rem;
}
.cc nav {
  background: #fff;
  /*px*/ padding: 5px 0 20px 0;
  /*rem*/ padding: 5 / 16rem 0 / 16rem 20 / 16rem 0 / 16rem;
}
.cc nav.pagination {
  text-align: center;
  /*px*/ padding: 5px 0;
  /*rem*/ padding: 5 / 16rem 0 / 16rem;
}
.cc nav.pagination ul {
  display: inline-block;
  margin: 0 auto;
  padding: 0;
}
.cc nav.pagination ul li {
  display: inline;
}
.cc nav.pagination ul li a {
  /*px*/ padding: 5px 10px;
  /*rem*/ padding: 5 / 16rem 10 / 16rem;
  /*px*/ margin: 5px;
  /*rem*/ margin: 5 / 16rem;
  display: inline-block;
  text-decoration: none;
}
.cc nav.pagination ul li a:hover,
.cc nav.pagination ul li a:focus {
  background: rgba(13, 68, 95, 0.3);
}
.cc nav.pagination ul li a.sel {
  background: #0d445f;
  color: #fff;
}
.cc .data {
  text-align: center;
}
.cc .data.flex p {
  /*px*/ margin: 10px 0;
  /*rem*/ margin: 10 / 16rem 0 / 16rem;
}
@media only screen and (max-width: 970px) {
  .cc .data.flex p {
    /*px*/ margin: 5px 0;
    /*rem*/ margin: 5 / 16rem 0 / 16rem;
  }
}
.cc .data.flex p:last-child {
  margin-bottom: 0;
}
.cc .data.flex > div {
  /*px*/ padding: 20px;
  /*rem*/ padding: 20 / 16rem;
}
@media only screen and (max-width: 768px) {
  .cc .data.flex > div {
    /*px*/ padding: 10px 0;
    /*rem*/ padding: 10 / 16rem 0 / 16rem;
  }
}
.cc .data.flex > div .border {
  /*px*/ padding: 20px;
  /*rem*/ padding: 20 / 16rem;
  height: 100%;
}
@media only screen and (max-width: 970px) {
  .cc .data.flex > div .border {
    /*px*/ padding: 10px;
    /*rem*/ padding: 10 / 16rem;
  }
}
.cc .data.flex-no-wrap .w-full {
  position: relative;
  border: none !important;
}
.cc .data.flex-no-wrap .w-full:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -1rem;
  height: 50%;
  width: 1px;
  background: #e0e0e0;
}
.cc .data.flex-no-wrap .w-full:last-child:after {
  display: none;
}
@media only screen and (max-width: 632px) {
  .cc .data.flex-no-wrap .w-full:last-child {
    grid-column: 1 / -1;
  }
}
.cc .data .bigger {
  /*px*/ font-size: 40px;
  /*rem*/ font-size: 40 / 16rem;
  font-weight: 700;
}
@media only screen and (max-width: 970px) {
  .cc .data .bigger {
    /*px*/ font-size: 24px;
    /*rem*/ font-size: 24 / 16rem;
  }
}
.cc .data .bigger span.red {
  color: #ba0020;
}
.cc .data .teaser {
  color: #ba0020;
  /*px*/ font-size: 20px;
  /*rem*/ font-size: 20 / 16rem;
  font-style: inherit;
}
@media only screen and (max-width: 970px) {
  .cc .data .teaser {
    /*px*/ font-size: 16px;
    /*rem*/ font-size: 16 / 16rem;
  }
}
.cc .data .soft {
  /*px*/ font-size: 14px;
  /*rem*/ font-size: 14 / 16rem;
}
.cc .timeline li {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}
.cc .timeline li .double {
  width: auto !important;
  right: 73px;
}
@media only screen and (max-width: 768px) {
  .cc .timeline li .double {
    width: 100% !important;
    right: inherit;
  }
}
.cc .timeline li .specifique-details {
  position: relative;
  right: 73px;
}
@media only screen and (max-width: 768px) {
  .cc .timeline li .specifique-details {
    right: inherit;
  }
}
.cc .timeline li .date {
  padding: 1.25rem 1.875rem 1.25rem 1.2rem;
  /*px*/ font-size: 22px;
  /*rem*/ font-size: 22 / 16rem;
  position: relative;
  width: 135px;
  text-align: center;
}
@media only screen and (max-width: 1100px) {
  .cc .timeline li .date {
    width: 135px;
  }
}
@media only screen and (max-width: 768px) {
  .cc .timeline li .date {
    /*px*/ padding: 0 0 10px;
    /*rem*/ padding: 0 / 16rem 0 / 16rem 10 / 16rem;
    /*px*/ font-size: 18px;
    /*rem*/ font-size: 18 / 16rem;
    width: 100%;
  }
}
.cc .timeline li .date:after {
  content: "";
  /*px*/ border-radius: 30px;
  /*rem*/ border-radius: 30 / 16rem;
  width: 20px;
  height: 20px;
  background: #273a7e;
  /* border: 5px solid @Cwh; */
  display: block;
  position: absolute;
  right: -10px;
  top: 26px;
  z-index: 1;
}
@media only screen and (max-width: 970px) {
  .cc .timeline li .date:after {
    width: 10px;
    height: 10px;
    right: -10px;
  }
}
@media only screen and (max-width: 768px) {
  .cc .timeline li .date:after {
    content: none;
  }
}
.cc .timeline li .date > span {
  background: #273a7e;
  color: #fff;
  /*px*/ padding: 5px 20px;
  /*rem*/ padding: 5 / 16rem 20 / 16rem;
  display: block;
}
.cc .timeline li .details {
  border-left: 1px solid #8e9298;
  /*px*/ padding: 20px;
  /*rem*/ padding: 20 / 16rem;
  width: 70%;
  text-align: justify;
}
@media only screen and (max-width: 1100px) {
  .cc .timeline li .details {
    width: 80%;
  }
}
@media only screen and (max-width: 768px) {
  .cc .timeline li .details {
    width: 100%;
    border: none;
    /*px*/ padding: 0 0 15px;
    /*rem*/ padding: 0 / 16rem 0 / 16rem 15 / 16rem;
  }
}
.cc .timeline li .details p {
  margin-top: 0;
  /*px*/ margin-bottom: 10px;
  /*rem*/ margin-bottom: 10 / 16rem;
  /*px*/ font-size: 14px;
  /*rem*/ font-size: 14 / 16rem;
}
@media only screen and (max-width: 970px) {
  .cc .timeline li .details p {
    /*px*/ margin-bottom: 5px;
    /*rem*/ margin-bottom: 5 / 16rem;
  }
}
.cc .timeline li .details p strong {
  color: #273a7e;
  /*px*/ font-size: 20px;
  /*rem*/ font-size: 20 / 16rem;
}
@media only screen and (max-width: 970px) {
  .cc .timeline li .details p strong {
    /*px*/ font-size: 16px;
    /*rem*/ font-size: 16 / 16rem;
  }
}
.cc blockquote {
  background: #f3f4f4;
  /*px*/ padding: 20px;
  /*rem*/ padding: 20 / 16rem;
  margin: 0;
}
@media only screen and (max-width: 768px) {
  .cc blockquote {
    /*px*/ padding: 10px;
    /*rem*/ padding: 10 / 16rem;
  }
}
.cc blockquote p {
  margin: 0;
}
.cc blockquote p cite {
  display: block;
  /*px*/ margin-top: 20px;
  /*rem*/ margin-top: 20 / 16rem;
  font-style: initial;
  /*px*/ font-size: 13px;
  /*rem*/ font-size: 13 / 16rem;
}
.cc blockquote p cite span {
  display: block;
  text-align: right;
}
.cc blockquote p cite span.author {
  color: #273a7e;
  text-transform: uppercase;
  font-weight: 700;
}
.cc.main .body .flex .insert {
  height: 100%;
  /*px*/ padding: 20px;
  /*rem*/ padding: 20 / 16rem;
}
.cc.main .body .flex .insert ul.styled,
.cc.main .body .flex .insert ol.styled {
  /*px*/ padding-left: 29px;
  /*rem*/ padding-left: 29 / 16rem;
}
.cc.main .body .insert {
  /*px*/ padding: 25px;
  /*rem*/ padding: 25 / 16rem;
  background: #f3f4f4;
}
.cc.main .body .insert.blue {
  background: #273a7e;
  color: #fff;
}
.cc.main .body .insert.blue p {
  color: #fff;
}
.cc.main .body .insert .banner p:first-child {
  /*px*/ margin-top: 0;
  /*rem*/ margin-top: 0 / 16rem;
}
.cc.main .body .insert .banner p:last-child {
  /*px*/ margin-bottom: 0;
  /*rem*/ margin-bottom: 0 / 16rem;
}
.cc.main .body .insert .banner p.teaser {
  /*px*/ font-size: 14px;
  /*rem*/ font-size: 14 / 16rem;
  font-style: initial;
  font-weight: 700;
}
.cc.main .body .insert .banner ul.styled li:before {
  /*px*/ font-size: 16px;
  /*rem*/ font-size: 16 / 16rem;
}
.cc.main .body .insert h1,
.cc.main .body .insert h2 {
  color: #ba0020;
  /*px*/ font-size: 16px;
  /*rem*/ font-size: 16 / 16rem;
  /*px*/ margin: 0 0 10px;
  /*rem*/ margin: 0 / 16rem 0 / 16rem 10 / 16rem;
  /*px*/ line-height: 30px;
  /*rem*/ line-height: 30 / 16rem;
}
.cc.main .body .insert h1:after,
.cc.main .body .insert h2:after {
  content: none;
}
.cc.main .body .insert h2:before {
  content: "";
  font-family: fts_commun;
  content: "\ECCD";
  /*px*/ font-size: 25px;
  /*rem*/ font-size: 25 / 16rem;
  /*px*/ padding: 5px;
  /*rem*/ padding: 5 / 16rem;
  /*px*/ border-radius: 30px;
  /*rem*/ border-radius: 30 / 16rem;
  color: #fff;
  background: #ba0020;
  font-weight: 300;
  vertical-align: sub;
  /*px*/ margin-right: 10px;
  /*rem*/ margin-right: 10 / 16rem;
}
.cc.main .body .insert h3 {
  /*px*/ font-size: 14px;
  /*rem*/ font-size: 14 / 16rem;
  /*px*/ margin: 0 0 5px;
  /*rem*/ margin: 0 / 16rem 0 / 16rem 5 / 16rem;
  text-transform: initial;
}
.cc.main .body .insert h3 + p {
  margin-top: 0;
}
.cc.main .body .insert.ctxt-contact {
  /*px*/ padding: 25px 25px 25px 70px;
  /*rem*/ padding: 25 / 16rem 25 / 16rem 25 / 16rem 70 / 16rem;
  position: relative;
}
.cc.main .body .insert.ctxt-contact.more {
  /*px*/ padding: 13px 40px 13px 20px;
  /*rem*/ padding: 13 / 16rem 40 / 16rem 13 / 16rem 20 / 16rem;
}
.cc.main .body .insert.ctxt-contact:before {
  content: "";
  font-family: fts_commun;
  content: "\EB0A";
  /*px*/ font-size: 25px;
  /*rem*/ font-size: 25 / 16rem;
  /*px*/ padding: 5px 8px;
  /*rem*/ padding: 5 / 16rem 8 / 16rem;
  /*px*/ border-radius: 30px;
  /*rem*/ border-radius: 30 / 16rem;
  color: #fff;
  background: #ba0020;
  position: absolute;
  top: 15px;
  left: 15px;
}
.cc.main .body .insert.ctxt-contact h2:before {
  content: none;
}
.cc.main .body .insert.ctxt-contact p {
  /*px*/ font-size: 16px;
  /*rem*/ font-size: 16 / 16rem;
}
.cc.main .body .insert.ctxt-cloud {
  /*px*/ padding: 25px 25px 25px 70px;
  /*rem*/ padding: 25 / 16rem 25 / 16rem 25 / 16rem 70 / 16rem;
  position: relative;
  border-left: 2px solid #ba0020;
}
.cc.main .body .insert.ctxt-cloud.more {
  /*px*/ padding: 13px 40px 13px 20px;
  /*rem*/ padding: 13 / 16rem 40 / 16rem 13 / 16rem 20 / 16rem;
}
.cc.main .body .insert.ctxt-cloud:before {
  content: "";
  font-family: fts_commun;
  content: "\EAB5";
  /*px*/ font-size: 25px;
  /*rem*/ font-size: 25 / 16rem;
  /*px*/ padding: 5px 8px;
  /*rem*/ padding: 5 / 16rem 8 / 16rem;
  /*px*/ border-radius: 30px;
  /*rem*/ border-radius: 30 / 16rem;
  color: #fff;
  background: #ba0020;
  position: absolute;
  top: 15px;
  left: 15px;
}
.cc.main .body .insert.ctxt-cloud h2 {
  text-transform: initial;
}
.cc.main .body .insert.ctxt-cloud h2:before {
  content: none;
}
.cc.main .body .insert.ctxt-quote {
  /*px*/ padding: 25px 25px 25px 70px;
  /*rem*/ padding: 25 / 16rem 25 / 16rem 25 / 16rem 70 / 16rem;
  position: relative;
}
.cc.main .body .insert.ctxt-quote.more {
  /*px*/ padding: 13px 40px 13px 20px;
  /*rem*/ padding: 13 / 16rem 40 / 16rem 13 / 16rem 20 / 16rem;
}
.cc.main .body .insert.ctxt-quote:before {
  content: "";
  font-family: fts_commun;
  content: "\EB69";
  /*px*/ font-size: 25px;
  /*rem*/ font-size: 25 / 16rem;
  /*px*/ padding: 5px 8px;
  /*rem*/ padding: 5 / 16rem 8 / 16rem;
  /*px*/ border-radius: 30px;
  /*rem*/ border-radius: 30 / 16rem;
  color: #fff;
  background: #ba0020;
  position: absolute;
  top: 15px;
  left: 15px;
}
.cc.main .body .insert.ctxt-quote h2 {
  text-transform: initial;
}
.cc.main .body .insert.ctxt-quote h2:before {
  content: none;
}
.cc .body .hasAside .insert {
  width: 400px;
  float: right;
  /*px*/ margin: 0 0 20px 20px;
  /*rem*/ margin: 0 / 16rem 0 / 16rem 20 / 16rem 20 / 16rem;
}
@media only screen and (max-width: 970px) {
  .cc .body .hasAside .insert {
    width: 100%;
    /*px*/ margin-bottom: 20px;
    /*rem*/ margin-bottom: 20 / 16rem;
  }
}
.cc .body #pdfDiv.pdf {
  height: 700px;
}
.cc .body #pdfDiv.pdf object {
  width: 100%;
  height: 100%;
}
.cc .team > div {
  border: 1px solid #e0e0e0;
  cursor: pointer;
}
.cc .team > div:hover .banner {
  /*px*/ padding: 5px;
  /*rem*/ padding: 5 / 16rem;
}
@media only screen and (max-width: 768px) {
  .cc .team > div:hover .banner {
    /*px*/ padding: 15px;
    /*rem*/ padding: 15 / 16rem;
  }
}
.cc .team > div:hover .banner .infos {
  display: none;
}
@media only screen and (max-width: 768px) {
  .cc .team > div:hover .banner .infos {
    display: block;
  }
}
.cc .team > div:hover .banner .social {
  display: block;
}
.cc .team > div:hover figure img:first-child {
  opacity: 1;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}
.cc .team > div:hover figure img:nth-child(2) {
  opacity: 0;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}
.cc .team > div figure {
  width: 100%;
  height: auto;
  margin: 0;
  position: relative;
}
.cc .team > div figure img:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
}
.cc .team > div .banner {
  /*px*/ padding: 10px;
  /*rem*/ padding: 10 / 16rem;
  height: 80px;
}
@media only screen and (max-width: 768px) {
  .cc .team > div .banner {
    height: auto;
  }
}
.cc .team > div .banner p {
  /*px*/ font-size: 13px;
  /*rem*/ font-size: 13 / 16rem;
  color: #8e9298;
  margin: 0;
  padding: 0;
  line-height: 1.3;
}
.cc .team > div .banner p strong {
  color: #273a7e;
  /*px*/ font-size: 15px;
  /*rem*/ font-size: 15 / 16rem;
}
.cc .team > div .social {
  display: none;
}
@media only screen and (max-width: 768px) {
  .cc .team > div .social {
    display: block;
  }
}
.cc .team > div .social ul {
  /*px*/ margin: 5px 0;
  /*rem*/ margin: 5 / 16rem 0 / 16rem;
}
.cc .team > div .social ul li {
  font-family: fts_commun;
  display: inline-block;
  /*px*/ font-size: 22px;
  /*rem*/ font-size: 22 / 16rem;
  /*px*/ padding: 0 5px;
  /*rem*/ padding: 0 / 16rem 5 / 16rem;
  margin: 0;
}
.cc .team > div .social ul li.email:before {
  content: "\EB0A";
  /*px*/ font-size: 23px;
  /*rem*/ font-size: 23 / 16rem;
}
.cc .team > div .social ul li.linkedin {
  vertical-align: top;
}
.cc .team > div .social ul li.linkedin:before {
  content: "\EDB3";
  /*px*/ font-size: 20px;
  /*rem*/ font-size: 20 / 16rem;
}
.cc div[aria-hidden="true"] {
  display: none;
}
.cc div[aria-hidden="false"] {
  display: block;
}
.cc ul.tabs {
  padding: 0;
  margin: 0;
  text-align: left;
  border-bottom: 1px solid #ba0020;
  line-height: 1.8;
}
.cc ul.tabs li {
  display: inline-block;
  margin: 0 0 -3px;
}
.cc ul.tabs li:before {
  content: "|";
  color: #ba0020;
}
.cc ul.tabs li:first-child:before {
  content: none;
}
.cc ul.tabs li a {
  color: #ba0020;
  /*px*/ padding: 10px;
  /*rem*/ padding: 10 / 16rem;
  /*px*/ font-size: 15px;
  /*rem*/ font-size: 15 / 16rem;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  transition: none;
  display: inline-block;
}
.cc ul.tabs li a:hover,
.cc ul.tabs li a:focus {
  color: #273a7e;
}
.cc ul.tabs li.sel a {
  color: #0d445f;
  font-weight: 700;
  position: relative;
}
.cc ul.tabs li.sel a:after {
  position: absolute;
  right: 0;
  bottom: -1px;
  left: 0;
  width: 40%;
  height: 0;
  margin: 0 auto;
  content: "";
  border: 2px solid #0d445f;
}
.cc.main div.tab {
  /*px*/ margin-top: 15px;
  /*rem*/ margin-top: 15 / 16rem;
}
@media only screen and (max-width: 768px) {
  .cc.main div.tab .flex {
    display: block;
  }
}
.cc.main div.tab .flex figure {
  /*px*/ margin: 10px auto 30px;
  /*rem*/ margin: 10 / 16rem auto 30 / 16rem;
  width: auto;
}
@media only screen and (max-width: 768px) {
  .cc.main div.tab .flex figure.flex10,
  .cc.main div.tab .flex figure.flex20,
  .cc.main div.tab .flex figure.flex25,
  .cc.main div.tab .flex figure.flex30,
  .cc.main div.tab .flex figure.flex33,
  .cc.main div.tab .flex figure.flex40,
  .cc.main div.tab .flex figure.flex50,
  .cc.main div.tab .flex figure.flex60,
  .cc.main div.tab .flex figure.flex70,
  .cc.main div.tab .flex figure.flex80,
  .cc.main div.tab .flex figure.flex90 {
    width: 100% !important;
  }
}
.cc.main div.tab .flex figure.flex10 {
  width: 10% !important;
}
.cc.main div.tab .flex figure.flex20 {
  width: 20%;
}
.cc.main div.tab .flex figure.flex25 {
  width: 25% !important;
}
.cc.main div.tab .flex figure.flex30 {
  width: 30%;
}
.cc.main div.tab .flex figure.flex33 {
  width: 33.3% !important;
}
.cc.main div.tab .flex figure.flex40 {
  width: 40% !important;
}
.cc.main div.tab .flex figure.flex50 {
  width: 50%;
}
.cc.main div.tab .flex figure.flex60 {
  width: 60% !important;
}
.cc.main div.tab .flex figure.flex70 {
  width: 70%;
}
.cc.main div.tab .flex figure.flex80 {
  width: 80% !important;
}
.cc.main div.tab .flex figure.flex90 {
  width: 90% !important;
}
.cc.main div.tab .flex figure.flex100 {
  width: 100% !important;
}
.cc.main div.tab .flex figure img {
  max-width: 100%;
}
.cc.main article {
  /*px*/ margin-bottom: 15px;
  /*rem*/ margin-bottom: 15 / 16rem;
}
.cc.main article header {
  margin: 0 auto;
  max-width: 1280px;
  /*px*/ padding: 0 30px;
  /*rem*/ padding: 0 / 16rem 30 / 16rem;
}
.cc.main article header h1 {
  /*px*/ margin: 0 0 15px;
  /*rem*/ margin: 0 / 16rem 0 / 16rem 15 / 16rem;
}
.cc.main article header p {
  margin: 0;
}
.cc.main article header div.img img {
  display: block;
  max-width: 100%;
  height: auto;
}
.cc.main article header.hero {
  position: relative;
  padding: 0;
  max-width: none;
  background: transparent;
}
.cc.main article header.hero divdiv.img {
  position: relative;
  max-height: 400px;
  overflow: hidden;
}
.cc.main article header.hero divdiv.img img {
  display: block;
  max-width: 100%;
  height: auto;
}
.cc.main article header.hero divdiv.img:before {
  background: rgba(0, 0, 0, 0.1);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  content: "";
}
.cc.main article header.hero div.img {
  position: relative;
  max-height: 400px;
  overflow: hidden;
}
.cc.main article header.hero div.img img {
  display: block;
  max-width: 100%;
  height: auto;
}
.cc.main article header.hero div.img:before {
  background: rgba(0, 0, 0, 0.1);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  content: "";
}
.cc.main article header.hero div.banner {
  position: absolute;
  /*px*/ top: 30px;
  /*rem*/ top: 30 / 16rem;
  left: 50%;
  text-align: left;
  width: 65%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  color: #fff;
}
@media only screen and (max-width: 768px) {
  .cc.main article header.hero div.banner {
    position: static;
    -webkit-transform: initial;
    transform: initial;
    color: #74787f;
    text-align: left;
    /*px*/ padding: 10px 10px 5px;
    /*rem*/ padding: 10 / 16rem 10 / 16rem 5 / 16rem;
    width: 100%;
  }
}
.cc.main article header.hero div.banner h1 {
  margin: 0;
  color: #fff;
  text-shadow: 1px 3px 2px rgba(0, 0, 0, 0.3);
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  display: inline-block;
}
@media only screen and (max-width: 768px) {
  .cc.main article header.hero div.banner h1 {
    background-color: inherit;
    color: #414141;
    text-shadow: none;
  }
}
.cc.main article header.hero div.banner p {
  color: #fff;
  /*px*/ margin: 15px 0 0 0;
  /*rem*/ margin: 15 / 16rem 0 / 16rem 0 / 16rem 0 / 16rem;
}
@media only screen and (max-width: 768px) {
  .cc.main article header.hero div.banner p {
    /*px*/ font-size: 18px;
    /*rem*/ font-size: 18 / 16rem;
    color: #74787f;
  }
}
.cc.main article footer {
  /*px*/ padding: 30px 0;
  /*rem*/ padding: 30 / 16rem 0 / 16rem;
}
.cc.main article .body {
  /*px*/ padding: 30px;
  /*rem*/ padding: 30 / 16rem;
  margin: 0 auto;
  max-width: 1280px;
}
@media only screen and (max-width: 768px) {
  .cc.main article .body {
    /*px*/ padding: 10px;
    /*rem*/ padding: 10 / 16rem;
  }
}
.cc.main article .body h2 {
  /*px*/ margin: 15px 0;
  /*rem*/ margin: 15 / 16rem 0 / 16rem;
  color: #273a7e;
  position: relative;
}
.cc.main article .body .paddings > h3 {
  margin-top: 0;
}
.cc.main article .body .paddings p + h3,
.cc.main article .body .paddings ul + h3 {
  /*px*/ margin: 25px 0;
  /*rem*/ margin: 25 / 16rem 0 / 16rem;
}
.cc.main article .body h3 {
  /*px*/ margin: 25px 0;
  /*rem*/ margin: 25 / 16rem 0 / 16rem;
  color: #273a7e;
}
.cc.main article .body > div {
  /*px*/ margin-top: 40px;
  /*rem*/ margin-top: 40 / 16rem;
}
.cc.main article .body > div:first-child {
  margin-top: 0;
}
.cc.main article .body > div section {
  /*px*/ margin-bottom: 15px;
  /*rem*/ margin-bottom: 15 / 16rem;
  /*px*/ padding: 25px;
  /*rem*/ padding: 25 / 16rem;
}
@media only screen and (max-width: 768px) {
  .cc.main article .body > div section {
    /*px*/ padding: 15px;
    /*rem*/ padding: 15 / 16rem;
  }
}
.cc.main article .body > div section:last-child {
  margin-top: 0;
}
.cc.main article .body > div section > h2 {
  margin-top: 0;
}
.cc.main article .body > div section.bg {
  background-color: #f3f4f4;
}
@media only screen and (max-width: 768px) {
  .cc.main article .body > div section > div > div.flex.orientation {
    display: flex;
    margin-bottom: 0;
  }
}
.cc.main article .body > div section > div > div.flex.orientation ol li:last-child,
.cc.main article .body > div section > div > div.flex.orientation ul li:last-child {
  margin-bottom: 0;
}
.cc.main article .body > div section > div > div.flex.orientation h2 {
  margin-top: 0;
}
.cc.main article .body > div section > div > div.flex.orientation.orientation-droite figure {
  margin: 0;
}
@media only screen and (max-width: 768px) {
  .cc.main article .body > div section > div > div.flex.orientation.orientation-droite > div.flex50 {
    padding: 0;
  }
}
.cc.main article .body > div section > div > div.flex.orientation.orientation-droite > div.flex50:first-child {
  /*px*/ padding-right: 30px;
  /*rem*/ padding-right: 30 / 16rem;
}
@media only screen and (max-width: 768px) {
  .cc.main article .body > div section > div > div.flex.orientation.orientation-droite > div.flex50:first-child {
    padding: 0;
  }
}
.cc.main article .body > div section > div > div.flex.orientation.orientation-droite > div.flex50:last-child {
  /*px*/ padding-left: 30px;
  /*rem*/ padding-left: 30 / 16rem;
}
@media only screen and (max-width: 768px) {
  .cc.main article .body > div section > div > div.flex.orientation.orientation-droite > div.flex50:last-child {
    padding: 0;
  }
}
.cc.main article .body > div section > div > div.flex.orientation.orientation-gauche figure {
  margin: 0;
}
@media only screen and (max-width: 768px) {
  .cc.main article .body > div section > div > div.flex.orientation.orientation-gauche > div.flex50 {
    padding: 0;
  }
}
.cc.main article .body > div section > div > div.flex.orientation.orientation-gauche > div.flex50:last-child {
  /*px*/ padding-left: 30px;
  /*rem*/ padding-left: 30 / 16rem;
}
@media only screen and (max-width: 768px) {
  .cc.main article .body > div section > div > div.flex.orientation.orientation-gauche > div.flex50:last-child {
    padding: 0;
  }
}
.cc.main article .body > div section > div > div.flex.orientation > div.flex50.center-align p {
  /*px*/ margin-bottom: 20px;
  /*rem*/ margin-bottom: 20 / 16rem;
}
.cc.main article .body > div section > div > div.flex.orientation > div.flex50.center-align p:last-child {
  margin-bottom: 0;
}
@media only screen and (max-width: 768px) {
  .cc.main article .body > div section > div > div.flex.orientation > .flex50 {
    order: 2;
  }
}
@media only screen and (max-width: 768px) {
  .cc.main article .body > div section > div > div.flex.orientation > .flex50.infos {
    order: 1;
    /*px*/ margin-bottom: 20px;
    /*rem*/ margin-bottom: 20 / 16rem;
  }
}
.cc.main article .body aside.sticky {
  width: 22%;
  position: relative;
  top: -97px;
}
@media only screen and (max-width: 970px) {
  .cc.main article .body aside.sticky {
    display: none;
  }
}
.cc.main article .body aside.sticky nav {
  padding: 0;
  position: sticky;
  /*px*/ top: 100px;
  /*rem*/ top: 100 / 16rem;
}
.cc.main article .body aside.sticky nav ul {
  padding: 0;
}
.cc.main article .body aside.sticky nav ul li {
  margin: 0;
  border-bottom: 1px solid #e0e0e0;
}
.cc.main article .body aside.sticky nav ul li a {
  background: #f3f4f4;
  color: #0d445f;
  text-decoration: none;
  width: 100%;
  /*px*/ padding: 10px 15px;
  /*rem*/ padding: 10 / 16rem 15 / 16rem;
  display: block;
  /*px*/ font-size: 17px;
  /*rem*/ font-size: 17 / 16rem;
}
.cc.main article .body aside.sticky nav ul li a[aria-current="true"] {
  color: #fff;
  background: #4869d3;
  /*px*/ font-size: 19px;
  /*rem*/ font-size: 19 / 16rem;
}
.cc.main article .body aside.sticky nav ul li[aria-current="true"] {
  border-bottom: none;
}
.cc.main article .body aside.sticky nav ul li[aria-current="true"] a {
  color: #fff;
  background: #4869d3;
  /*px*/ font-size: 19px;
  /*rem*/ font-size: 19 / 16rem;
}
.cc.main article .body aside.sticky nav ul li:last-child {
  border-bottom: none;
}
.cc.main article .body aside.sticky nav ul li.active a {
  color: #fff;
  background: #273a7e;
}
.cc.main article .body aside.sticky nav:nth-child(2) {
  top: 100px !important;
}
.cc.main article .body aside.sticky + div {
  width: 78%;
  margin-top: 0;
  /*px*/ padding-left: 30px;
  /*rem*/ padding-left: 30 / 16rem;
}
@media only screen and (max-width: 970px) {
  .cc.main article .body aside.sticky + div {
    width: 100%;
    padding-left: 0;
  }
}
.cc.main article .body aside.sticky + div + div {
  padding-left: 0;
  width: 100%;
}
.cc.main article .body aside.falsetabs {
  width: 100%;
}
.cc.main article .body aside.falsetabs nav ul {
  text-align: justify;
  margin: 0;
  border-bottom: 3px solid #e0e0e0;
  height: 38px;
  display: flex;
  justify-content: center;
  /*&:after {
									display: inline-block;
									width: 95%;
									content: '';
								}*/
}
@media only screen and (max-width: 1100px) {
  .cc.main article .body aside.falsetabs nav ul {
    height: auto;
    text-align: left;
  }
}
.cc.main article .body aside.falsetabs nav ul li {
  margin: 0;
  width: 100%;
  text-align: center;
  /*px*/ padding: 5px 10px 10px;
  /*rem*/ padding: 5 / 16rem 10 / 16rem 10 / 16rem;
}
.cc.main article .body aside.falsetabs nav ul li .ancre {
  font-size: 0.9rem;
  font-weight: bold;
}
@media only screen and (max-width: 1100px) {
  .cc.main article .body aside.falsetabs nav ul li {
    display: block;
    width: 100%;
    text-align: left;
  }
}
.cc.main article .body aside.falsetabs nav ul li a {
  text-decoration: none;
}
.cc.main article .body aside.falsetabs nav:nth-child(2) {
  display: none !important;
}
.cc.main article .body aside.falsetabs + div {
  margin-top: 0;
  width: 100%;
  padding-left: 0;
}
.cc.main article .body aside li {
  transition: 0.3s ease-in-out;
}
.cc.main article .body aside li.highlight {
  border-bottom: 3px solid #273a7e;
}
.cc.main article .body aside + div {
  margin-top: 0;
}
.cc.main.ctxt-article {
  background: white;
}
.cc.main.ctxt-article .intro.hero h1 {
  animation-duration: 2s;
  animation-name: fadeInRight;
  animation-direction: normal;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  display: inline-block;
}
@media only screen and (max-width: 768px) {
  .cc.main.ctxt-article .intro.hero h1 {
    background-color: inherit;
  }
}
.cc.main.ctxt-list {
  background-color: white;
}
.cc.main.ctxt-list .intro.hero h1 {
  animation-duration: 2s;
  animation-name: fadeInRight;
  animation-direction: normal;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  display: inline-block;
}
@media only screen and (max-width: 768px) {
  .cc.main.ctxt-list .intro.hero h1 {
    background-color: inherit;
  }
}
.cc.main.ctxt-list article .body > div section {
  border: none;
  padding: 0;
}
.cc.main.ctxt-list article .body > div section .tiles {
  display: flex;
  flex-wrap: wrap;
}
.cc.main.ctxt-home article header.hero div.banner {
  text-align: left;
  width: 40%;
  left: 30%;
  top: 30%;
}
@media only screen and (max-width: 1100px) {
  .cc.main.ctxt-home article header.hero div.banner {
    width: 40%;
    left: 70%;
  }
}
@media only screen and (max-width: 970px) {
  .cc.main.ctxt-home article header.hero div.banner {
    left: 50%;
    text-align: left;
    width: 65%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
@media only screen and (max-width: 768px) {
  .cc.main.ctxt-home article header.hero div.banner {
    position: static;
    width: 100%;
    transform: initial;
    background: #f3f4f4;
  }
}
.cc.main.ctxt-home article header div.img::before {
  background: rgba(0, 0, 0, 0.24);
}
.cc.main.ctxt-home article .body {
  padding: 0;
  max-width: none;
}
.cc.main.ctxt-home article .body > div section {
  border: none;
  /*px*/ padding: 20px 0;
  /*rem*/ padding: 20 / 16rem 0 / 16rem;
  margin-bottom: 0;
  background: white;
  /*	div.reference:nth-child(1) > div:nth-child(1) > figure:nth-child(1) > img:nth-child(1) {
								max-width:200px;
								@media @small {
									max-width:155px;
								}
								}*/
}
.cc.main.ctxt-home article .body > div section:nth-child(n+3) {
  margin-bottom: 24px;
  box-shadow: 0 4px 4px 1px rgba(0, 0, 0, 0.3);
}
.cc.main.ctxt-home article .body > div section > div {
  margin: 0 auto;
  max-width: 1280px;
  /*px*/ padding: 30px;
  /*rem*/ padding: 30 / 16rem;
}
@media only screen and (max-width: 768px) {
  .cc.main.ctxt-home article .body > div section > div {
    /*px*/ padding: 10px;
    /*rem*/ padding: 10 / 16rem;
  }
}
.cc.main.ctxt-home article .body > div section h2 {
  color: #273a7e;
  text-align: center;
  text-transform: uppercase;
  font-weight: 800;
  /*px*/ font-size: 30px;
  /*rem*/ font-size: 30 / 16rem;
  /*&:after {
									content:' ';
									display:block;
									border: 1px solid @C4;
									width : 70px;
									position: absolute;
									left: 48%;
									}*/
}
.cc.main.ctxt-home article .body > div section .tiles.tiles-p2 .reference {
  width: 20%;
}
@media only screen and (max-width: 970px) {
  .cc.main.ctxt-home article .body > div section .tiles.tiles-p2 .reference {
    width: 33%;
  }
}
@media only screen and (max-width: 768px) {
  .cc.main.ctxt-home article .body > div section .tiles.tiles-p2 .reference {
    width: 100%;
    /*px*/ padding: 5px 0;
    /*rem*/ padding: 5 / 16rem 0 / 16rem;
  }
}
.cc.main.ctxt-home article .body > div section .tiles.tiles-p2 .reference figure {
  height: 200px;
}
.cc.main.ctxt-home article .body > div section .tiles.tiles-p2 .reference figure img {
  max-width: 140px;
}
.cc.main.ctxt-home article .body > div section .tiles.tiles-p2 .reference figure img:nth-child(2n) {
  display: none;
}
.cc.main.ctxt-home article .body > div section .tiles.tiles-p2 .reference figure:hover img:first-child {
  display: none;
}
.cc.main.ctxt-home article .body > div section .tiles.tiles-p2 .reference figure:hover img:nth-child(2n) {
  display: block;
}
.cc.main.ctxt-home article .body > div section .tiles.tiles-p2 .reference .banner {
  /*px*/ padding: 15px;
  /*rem*/ padding: 15 / 16rem;
}
.cc.main.ctxt-home article .body > div section .tiles.tiles-p2 .reference .banner h2 {
  /*px*/ font-size: 14px;
  /*rem*/ font-size: 14 / 16rem;
  margin: 0;
}
.cc.main.ctxt-home article .body > div section .tiles.tiles-p2 .reference .banner .actions a {
  /*px*/ font-size: 12px;
  /*rem*/ font-size: 12 / 16rem;
  /*px*/ padding: 10px;
  /*rem*/ padding: 10 / 16rem;
}
.cc.main.ctxt-home article .body > div section .tiles.tiles-p2 .reference .banner .actions a:after {
  content: none;
}
.cc.main.ctxt-home article .body > div section .encart-highlight {
  position: relative;
  padding: 1rem;
  background-color: #273a7e;
}
.cc.main.ctxt-home article .body > div section .encart-highlight h2 {
  color: white;
}
.cc.main.ctxt-home article .body > div section.carrefour {
  background: #f3f4f4;
}
.cc.main.ctxt-home article .body > div section.carrefour .tiles > .tile {
  width: 25%;
  /*px*/ padding: 5px;
  /*rem*/ padding: 5 / 16rem;
}
@media only screen and (max-width: 1100px) {
  .cc.main.ctxt-home article .body > div section.carrefour .tiles > .tile {
    width: 33%;
  }
}
@media only screen and (max-width: 768px) {
  .cc.main.ctxt-home article .body > div section.carrefour .tiles > .tile {
    width: 50%;
  }
}
@media only screen and (max-width: 400px) {
  .cc.main.ctxt-home article .body > div section.carrefour .tiles > .tile {
    width: 100%;
  }
}
.cc.main.ctxt-home article .body > div section.carrefour .tiles > .tile > .tile {
  background: #273a7e;
  height: 100%;
}
.cc.main.ctxt-home article .body > div section.carrefour .tiles > .tile figure {
  margin: 0;
}
.cc.main.ctxt-home article .body > div section.carrefour .tiles > .tile figure img {
  padding: 8px;
}
.cc.main.ctxt-home article .body > div section.carrefour .tiles > .tile .banner {
  /*px*/ padding: 10px 20px 20px;
  /*rem*/ padding: 10 / 16rem 20 / 16rem 20 / 16rem;
}
.cc.main.ctxt-home article .body > div section.carrefour .tiles > .tile a {
  text-decoration: none;
  height: 100%;
  display: block;
  -webkit-box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.3);
}
.cc.main.ctxt-home article .body > div section.carrefour .tiles > .tile a:hover {
  box-shadow: none;
}
.cc.main.ctxt-home article .body > div section.carrefour .tiles > .tile a h2 {
  /*px*/ font-size: 22px;
  /*rem*/ font-size: 22 / 16rem;
  color: #fff;
  margin: 0;
  min-height: 65px;
}
.cc.main.ctxt-home article .body > div section.carrefour .tiles > .tile a h2:after {
  content: none !important;
}
@media only screen and (max-width: 768px) {
  .cc.main.ctxt-home article .body > div section.carrefour .tiles > .tile a h2 {
    min-height: 0;
  }
}
.cc.main.ctxt-home article .body > div section.carrefour .tiles > .tile a p {
  /*px*/ font-size: 14px;
  /*rem*/ font-size: 14 / 16rem;
  color: #fff;
  text-align: center;
}
.cc.main.ctxt-home article .body > div section.carrefour .tiles > .tile a p:last-child {
  margin: 0;
}
.cc.main.ctxt-home article .body > div section.actu {
  background: #f3f4f4;
}
@media only screen and (max-width: 970px) {
  .cc.main.ctxt-home article .body > div section.actu .tiles {
    width: 100%;
  }
}
.cc.main.ctxt-home article .body > div section.actu .tiles > .tile {
  width: 33%;
  /*px*/ padding: 5px;
  /*rem*/ padding: 5 / 16rem;
}
@media only screen and (max-width: 768px) {
  .cc.main.ctxt-home article .body > div section.actu .tiles > .tile {
    width: 100%;
  }
}
.cc.main.ctxt-home article .body > div section.actu .tiles > .tile .banner {
  /*px*/ padding: 10px 20px 20px;
  /*rem*/ padding: 10 / 16rem 20 / 16rem 20 / 16rem;
}
.cc.main.ctxt-home article .body > div section.actu .tiles > .tile a {
  text-decoration: none;
  display: block;
  display: flex;
  flex-wrap: wrap;
}
.cc.main.ctxt-home article .body > div section.actu .tiles > .tile a:hover {
  -webkit-box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.3);
}
.cc.main.ctxt-home article .body > div section.actu .tiles > .tile a .banner {
  width: 60%;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
.cc.main.ctxt-home article .body > div section.actu .tiles > .tile a figure {
  width: 40%;
  margin: 0;
}
.cc.main.ctxt-home article .body > div section.actu .tiles > .tile a figure img {
  min-height: 100%;
  height: auto;
}
.cc.main.ctxt-home article .body > div section.actu .tiles > .tile a h2 {
  /*px*/ font-size: 24px;
  /*rem*/ font-size: 24 / 16rem;
  color: #273a7e;
  margin: 0;
  text-align: left;
}
.cc.main.ctxt-home article .body > div section.actu .flex .tiles {
  width: 100%;
}
@media only screen and (max-width: 970px) {
  .cc.main.ctxt-home article .body > div section.actu .flex .tiles {
    width: 100%;
  }
}
.cc.main.ctxt-home article .body > div section.actu .flex .tiles > h2 {
  width: 100%;
}
.cc.main.ctxt-home article .body > div section.actu .flex .inserts {
  width: 30%;
  /*px*/ padding-left: 20px;
  /*rem*/ padding-left: 20 / 16rem;
}
@media only screen and (max-width: 970px) {
  .cc.main.ctxt-home article .body > div section.actu .flex .inserts {
    width: 100%;
    padding-left: 0;
  }
}
.cc.main.ctxt-home article .body > div section.actu .flex .inserts small {
  display: block;
  font-size: 60%;
}
.cc.main.ctxt-home article .body > div section.actu .flex .inserts > div {
  background: #e0e0e0;
  text-align: center;
}
.cc.main.ctxt-home article .body > div section.actu .flex .inserts > div h2:before {
  content: none;
}
.cc.main.ctxt-home article .body .data {
  text-align: center;
}
@media only screen and (max-width: 970px) {
  .cc.main.ctxt-home article .body .data {
    text-align: center;
  }
}
.cc.main.ctxt-home article .body .data > div {
  border-right: 1px solid #e0e0e0;
  /*px*/ padding: 0 20px;
  /*rem*/ padding: 0 / 16rem 20 / 16rem;
}
@media only screen and (max-width: 970px) {
  .cc.main.ctxt-home article .body .data > div {
    /*px*/ padding: 20px 0;
    /*rem*/ padding: 20 / 16rem 0 / 16rem;
  }
}
@media only screen and (max-width: 970px) {
  .cc.main.ctxt-home article .body .data > div {
    border: none;
  }
}
.cc.main.ctxt-home article .body .data > div:last-child {
  border: none;
}
.cc.main.ctxt-home article .body .data > div .bigger {
  color: #273a7e;
  /*px*/ font-size: 50px;
  /*rem*/ font-size: 50 / 16rem;
  font-weight: 400;
}
.cc.main.ctxt-home article .body .data > div .teaser {
  /*px*/ padding-top: 10px;
  /*rem*/ padding-top: 10 / 16rem;
}
.cc.main.ctxt-home article .body .data > div .soft {
  text-transform: uppercase;
}
@-webkit-keyframes fadeRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(10%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes fadeRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(10%);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}
@-o-keyframes fadeRight {
  0% {
    opacity: 0;
    -o-transform: translateX(10%);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}
@keyframes fadeRight {
  0% {
    opacity: 0;
    transform: translateX(10%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes fadeInRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}
@-o-keyframes fadeInRight {
  0% {
    opacity: 0;
    -o-transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.complementary > nav {
  display: none !important;
}
.picto {
  display: flex;
}
.picto:before {
  content: ' ';
  height: 40px;
  width: 55px;
  background-repeat: no-repeat;
  display: block;
}
.atom:before {
  background-image: url('/partage/fr/CSD/EUROTVS/assets/articles/relations-humaines/atom-variant.svg');
}
.happy:before {
  background-image: url('/partage/fr/CSD/EUROTVS/assets/articles/relations-humaines/emoticon-happy-outline.svg');
}
.hand:before {
  margin-right: 5px;
  background-image: url('/partage/fr/CSD/EUROTVS/assets/articles/relations-humaines/hand-heart.svg');
}
.human:before {
  background-image: url('/partage/fr/CSD/EUROTVS/assets/articles/relations-humaines/human-handsup.svg');
}
.infinity:before {
  background-image: url('/partage/fr/CSD/EUROTVS/assets/articles/relations-humaines/infinity.svg');
}
.lightbulb-on:before {
  background-image: url('/partage/fr/CSD/EUROTVS/assets/articles/relations-humaines/lightbulb-on.svg');
}
.timeline {
  display: flex;
  margin-top: 30px;
  flex-direction: column;
}
.timeline > div {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}
@media only screen and (max-width: 768px) {
  .timeline > div {
    flex-wrap: wrap;
  }
}
.timeline > div:last-child > .date {
  height: 50px;
}
.timeline > div:last-child > .date:after {
  border-bottom: none;
}
.timeline > div > .date p {
  text-align: center;
  background: #273a7e;
  background-attachment: fixed;
  display: inline-block;
  height: 72px;
  margin-bottom: 3px;
  position: relative;
  width: 100px;
  color: white;
  font-weight: bold;
  padding: 10px;
  /*px*/ font-size: 14px;
  /*rem*/ font-size: 14 / 16rem;
}
.timeline > div > .date p:before {
  border-bottom: 20px solid #273a7e;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  content: "";
  height: 0;
  left: 0;
  position: absolute;
  top: -20px;
  width: 0;
}
.timeline > div > .date p:after {
  border-bottom: 20px solid white;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  content: "";
  height: 0;
  left: 0;
  position: absolute;
  bottom: 0;
  width: 0;
}
.timeline > div .contenu {
  /*px*/ margin-left: 24px;
  /*rem*/ margin-left: 24 / 16rem;
  /*px*/ margin-top: 15px;
  /*rem*/ margin-top: 15 / 16rem;
  width: 80%;
}
@media only screen and (max-width: 768px) {
  .timeline > div .contenu {
    margin: 0;
    width: 100%;
  }
}
.timeline > div .contenu p {
  margin: 0 0 10px 0;
}
@media only screen and (max-width: 768px) {
  .timeline > div .contenu p {
    margin: 0 0 20px 0;
  }
}
.lightbox-img-opened {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
  height: 100%;
  width: 100%;
  left: 0;
  overflow-y: scroll;
  position: fixed;
  text-align: center;
  top: 0;
  z-index: 1500;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lightbox-img-opened img {
  max-height: 80vh;
  max-width: 100vw;
}
#videohtml5_1 {
  width: 100%;
}
/****************************************/
/********** Styles CNCM 2019 ************/
/****************************************/
/* Description :                        */
/*  - Styles CNCM/Tiles              	*/
/* Responsabilité :                     */
/*  - SB        						*/
/* Historique :                         */
/*  - 04/04/2019 : création             */
/****************************************/
/****************************************/
.cc.main .tiles {
  display: flex;
  flex-wrap: wrap;
}
.cc.main .tiles .team {
  width: 33%;
  position: relative;
  text-align: center;
  /*px*/ padding: 15px;
  /*rem*/ padding: 15 / 16rem;
}
@media only screen and (max-width: 1100px) {
  .cc.main .tiles .team {
    width: 33%;
  }
}
@media only screen and (max-width: 970px) {
  .cc.main .tiles .team {
    width: 50%;
  }
}
@media only screen and (max-width: 768px) {
  .cc.main .tiles .team {
    width: 100%;
    /*px*/ padding: 10px 0;
    /*rem*/ padding: 10 / 16rem 0 / 16rem;
  }
}
.cc.main .tiles .reference {
  width: 33%;
  /*px*/ padding: 15px;
  /*rem*/ padding: 15 / 16rem;
}
@media only screen and (max-width: 970px) {
  .cc.main .tiles .reference {
    width: 50%;
  }
}
@media only screen and (max-width: 768px) {
  .cc.main .tiles .reference {
    width: 100%;
  }
}
.cc.main .tiles .reference > div {
  position: relative;
}
.cc.main .tiles .reference figure {
  height: 280px;
  border: 1px solid #e0e0e0;
  margin: 0;
  position: relative;
}
.cc.main .tiles .reference figure img {
  width: auto;
  max-width: 250px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.cc.main .tiles .reference .banner {
  background: #f3f4f4;
  /*px*/ padding: 30px;
  /*rem*/ padding: 30 / 16rem;
}
.cc.main .tiles .reference .banner h2 {
  margin-top: 0;
  /*px*/ font-size: 16px;
  /*rem*/ font-size: 16 / 16rem;
}
.cc.main .tiles .reference .banner h3 {
  color: #414141;
  text-transform: initial;
  /*px*/ font-size: 16px;
  /*rem*/ font-size: 16 / 16rem;
  /*px*/ margin: 15px 0;
  /*rem*/ margin: 15 / 16rem 0 / 16rem;
}
.cc.main .tiles.tiles-p2:hover a,
.cc.main .tiles.split-tile:hover a {
  background: #fff;
  color: white;
}
@media only screen and (max-width: 768px) {
  .cc.main .tiles.tiles-p2 a,
  .cc.main .tiles.split-tile a {
    color: white;
  }
}
.cc.main .tiles.tiles-p2 .reference .banner,
.cc.main .tiles.split-tile .reference .banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(65, 65, 65, 0.8);
  color: #fff;
  text-align: center;
  display: none;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media only screen and (max-width: 768px) {
  .cc.main .tiles.tiles-p2 .reference .banner,
  .cc.main .tiles.split-tile .reference .banner {
    display: flex;
  }
}
.cc.main .tiles.tiles-p2 .reference .banner .readmore,
.cc.main .tiles.split-tile .reference .banner .readmore {
  display: none;
}
.cc.main .tiles.tiles-p2 .reference .banner h2,
.cc.main .tiles.split-tile .reference .banner h2 {
  color: #fff;
}
.cc.main .tiles.tiles-p2 .reference .banner h2:after,
.cc.main .tiles.split-tile .reference .banner h2:after {
  content: none;
}
.cc.main .tiles.tiles-p2 .reference .banner .actions a,
.cc.main .tiles.split-tile .reference .banner .actions a {
  border: 1px solid #fff;
  background: transparent;
}
.cc.main .tiles.tiles-p2 .reference .banner .actions a:hover,
.cc.main .tiles.split-tile .reference .banner .actions a:hover,
.cc.main .tiles.tiles-p2 .reference .banner .actions a:focus,
.cc.main .tiles.split-tile .reference .banner .actions a:focus {
  background: #fff;
  color: #414141;
}
.cc.main .tiles.tiles-p2 .reference:hover .banner,
.cc.main .tiles.split-tile .reference:hover .banner {
  display: flex;
}
.cc.main .tiles.tiles-p1 .reference .banner .actions {
  position: absolute;
  top: 240px;
  left: 0;
  margin: 0;
}
.cc.main .tiles.split-tile .reference {
  width: 100%;
}
.cc.main .tiles.split-tile .reference figure {
  height: 200px;
}
.cc.main .tiles.split-tile .reference figure img {
  max-width: 450px;
}
@media only screen and (max-width: 768px) {
  .cc.main .tiles.split-tile .reference figure img {
    max-width: 250px;
  }
}
.cc.main .tiles > .tile-img {
  width: 31%;
  position: relative;
  /*px*/ margin: 20px 20px 0 0;
  /*rem*/ margin: 20 / 16rem 20 / 16rem 0 / 16rem 0 / 16rem;
}
@media only screen and (max-width: 768px) {
  .cc.main .tiles > .tile-img {
    width: 46%;
  }
}
@media only screen and (max-width: 400px) {
  .cc.main .tiles > .tile-img {
    width: 100%;
    /*px*/ margin: 0 0 20px 0;
    /*rem*/ margin: 0 / 16rem 0 / 16rem 20 / 16rem 0 / 16rem;
  }
}
.cc.main .tiles > .tile-img:last-child {
  margin-right: 0;
}
.cc.main .tiles > .tile-img a:hover figure + div {
  background: rgba(34, 34, 34, 0.8);
}
.cc.main .tiles > .tile-img figure {
  margin: 0;
  display: block;
}
.cc.main .tiles > .tile-img figure img {
  width: 100%;
  height: auto;
}
.cc.main .tiles > .tile-img figure + div {
  position: absolute;
  bottom: 4px;
  left: 0;
  background: rgba(34, 34, 34, 0.2);
  /*px*/ padding: 15px;
  /*rem*/ padding: 15 / 16rem;
  width: 100%;
}
.cc.main .tiles > .tile-img h1 {
  margin: 0;
  color: #fff;
  /*px*/ font-size: 18px;
  /*rem*/ font-size: 18 / 16rem;
  text-transform: initial;
  text-align: center;
}
.cc.main .tiles .morecontent span {
  display: none;
}
.cc.main .tiles .readmore {
  /* text-align: right;
						 */
}
.cc.main .tiles .readmore p {
  text-align: justify;
}
.cc.main .tiles .morelink {
  display: inline-block;
  background: #273a7e;
  /*px*/ padding: 5px 30px 5px 10px;
  /*rem*/ padding: 5 / 16rem 30 / 16rem 5 / 16rem 10 / 16rem;
  color: #fff;
  text-decoration: none;
  position: relative;
}
.cc.main .tiles .morelink:hover {
  background: #3e5dc9;
}
.cc.main .tiles .morelink:after {
  font-family: fts_commun;
  content: "\EB18";
  /*px*/ font-size: 16px;
  /*rem*/ font-size: 16 / 16rem;
  /*px*/ padding-left: 7px;
  /*rem*/ padding-left: 7 / 16rem;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.cc.main .tiles .morelink.less:after {
  content: "\EB17";
}
/****************************************/
/******* Styles CSD-PAYSURF 2019 ********/
/****************************************/
/* Description :                        */
/*  - Styles CSD-PAYSURF/Form          */
/* Responsabilité :                     */
/*  - SB - AF       					*/
/* Historique :                         */
/*  - 06/02/2020 : création             */
/****************************************/
/****************************************/
.cc.main form .mandatory {
  color: red;
}
.cc.main form .err-msg {
  color: red;
  display: block;
  /*px*/ padding-top: 8px;
  /*rem*/ padding-top: 8 / 16rem;
  /*px*/ font-size: 12px;
  /*rem*/ font-size: 12 / 16rem;
}
.cc.main form .form-group {
  /*px*/ margin: 15px 0;
  /*rem*/ margin: 15 / 16rem 0 / 16rem;
}
.cc.main form .form-group label,
.cc.main form .form-group .form-label {
  /*px*/ margin-bottom: 5px;
  /*rem*/ margin-bottom: 5 / 16rem;
  display: block;
}
@media only screen and (max-width: 768px) {
  .cc.main form .form-group label,
  .cc.main form .form-group .form-label {
    width: 100% !important;
    text-align: left;
  }
}
.cc.main form .form-group > [id^="rd"],
.cc.main form .form-group [id^="chk"] {
  display: inline-block;
  /*px*/ margin-right: 30px;
  /*rem*/ margin-right: 30 / 16rem;
}
.cc.main form .form-group > [id^="rd"] + div,
.cc.main form .form-group [id^="chk"] + div {
  display: inline-block;
  margin: 0;
}
.cc.main form .form-group > [id^="rd"] + div > div,
.cc.main form .form-group [id^="chk"] + div > div {
  display: inline-block;
  /*px*/ margin-right: 15px;
  /*rem*/ margin-right: 15 / 16rem;
}
.cc.main form .form-group .form-control {
  /* .px-to-rem(margin-bottom, 30); */
}
@media only screen and (max-width: 768px) {
  .cc.main form .form-group .form-control {
    width: 100% !important;
  }
}
.cc.main form .form-group .form-control.invalid {
  margin-bottom: 0;
}
.cc.main form .form-group .form-control[role="radiogroup"] label,
.cc.main form .form-group .form-control[role="radiogroup"] .form-label {
  display: inline;
}
.cc.main form .form-group .form-control input {
  width: 100%;
  /*px*/ padding: 10px;
  /*rem*/ padding: 10 / 16rem;
}
.cc.main form .form-group .form-control input[type="radio"] {
  width: auto;
}
.cc.main form .form-group .form-control textarea {
  width: 100%;
  min-height: 150px;
  /*px*/ padding: 10px;
  /*rem*/ padding: 10 / 16rem;
}
.cc.main form .form-group .form-control select {
  width: 100%;
  /*px*/ padding: 10px;
  /*rem*/ padding: 10 / 16rem;
}
.cc.main form .form-group .form-control > div {
  /*px*/ margin-bottom: 10px;
  /*rem*/ margin-bottom: 10 / 16rem;
}
.cc.main form .form-group .form-control > div:last-child {
  margin-bottom: 0;
}
.cc.main form .form-group #rdCookieReseaux,
.cc.main form .form-group #rdCookieMarketing,
.cc.main form .form-group #rdCookieAudience {
  width: 100%;
  text-align: center;
}
.cc.main form .form-group #rdCookieReseaux + .form-control,
.cc.main form .form-group #rdCookieMarketing + .form-control,
.cc.main form .form-group #rdCookieAudience + .form-control {
  width: 100%;
  /*px*/ margin-top: 15px;
  /*rem*/ margin-top: 15 / 16rem;
}
.cc.main form .button {
  /*px*/ margin: 0;
  /*rem*/ margin: 0 / 16rem;
  -webkit-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
  width: 100%;
  background: #fff;
  color: #414141;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border: none;
  -webkit-box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.1);
}
.cc.main form .button:hover,
.cc.main form .button:focus {
  background: #414141;
  color: #fff;
}
.cc.main form .flex {
  /*px*/ margin-top: -10px;
  /*rem*/ margin-top: -10 / 16rem;
}
.cc.main form .flex .form-group {
  /*px*/ padding: 0 20px 0 0;
  /*rem*/ padding: 0 / 16rem 20 / 16rem 0 / 16rem 0 / 16rem;
  /*px*/ margin: 10px 0;
  /*rem*/ margin: 10 / 16rem 0 / 16rem;
}
@media only screen and (max-width: 970px) {
  .cc.main form .flex .form-group {
    padding: 0;
  }
}
.cc.main form .flex .form-group.flex50:nth-child(even) {
  padding: 0;
}
.cc .desktop,
.cc .mobile {
  padding: 0 !important;
}
.cc #slider:focus {
  color: #002f5f;
}
.cc #pause_0:before,
.cc #pause_1:before,
.cc #pause_2:before,
.cc #pause_3:before {
  background: url("/partage/fr/CSD/EUROTVS/assets/articles/accueil/pause.png") no-repeat;
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  position: relative;
  left: -9px;
  top: -1px;
}
.cc #play_0,
.cc #play_1,
.cc #play_2,
.cc #play_3 {
  display: none;
}
.cc #play_0:before,
.cc #play_1:before,
.cc #play_2:before,
.cc #play_3:before {
  background: url("/partage/fr/CSD/EUROTVS/assets/articles/accueil/play.png") no-repeat;
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  position: relative;
  left: -9px;
  top: -1px;
}
.cc button#pause_0,
.cc button#pause_1,
.cc button#pause_2,
.cc button#pause_3,
.cc button#play_0,
.cc button#play_1,
.cc button#play_2,
.cc button#play_3 {
  border: none;
  background: white;
  height: 19px;
  width: 15px;
  border-radius: 50px;
}
.cc .bouton_carrousel_1 {
  position: relative;
}
.cc .bouton_carrousel_1 button {
  position: absolute;
  bottom: 50px;
  left: 5px;
}
@media only screen and (max-width: 768px) {
  .cc .bouton_carrousel_1 button {
    bottom: -90px;
    z-index: 1;
  }
}
.cc .desktop.header-carrousel .slider {
  margin: 0;
}
.cc .desktop.header-carrousel .slider-inner div {
  padding: 0 !important;
  background: none !important;
}
.cc .desktop.header-carrousel .slider-buttons {
  margin: 0 !important;
  background: white;
}
.cc .desktop.header-carrousel .slider-buttons button {
  float: none;
  width: auto;
  position: absolute;
  top: -240px;
  color: white;
}
@media only screen and (max-width: 970px) {
  .cc .desktop.header-carrousel .slider-buttons button {
    top: -175px;
  }
}
.cc .desktop.header-carrousel .slider-buttons button.prev {
  left: 35px;
  z-index: 10;
}
.cc .desktop.header-carrousel .slider-buttons button.next {
  right: 35px;
  border-left: none;
  z-index: 10;
}
.cc .desktop.header-carrousel .slider-buttons button.prev:before {
  content: "\e605";
}
.cc .desktop.header-carrousel .slider-buttons button.prev:before:hover {
  color: #ba0020;
}
.cc .desktop.header-carrousel .slider-buttons button.next:before {
  content: "\e636";
}
.cc .desktop.header-carrousel .slider-buttons button.next:before:hover {
  color: #ba0020;
}
.cc .desktop.header-carrousel .slider-buttons button:hover {
  color: #ba0020;
}
.cc .desktop.header-carrousel .slider-buttons button::before {
  font-family: fts_commun;
  font-size: 32px;
  font-size: 3rem;
}
.cc .desktop.header-carrousel .slider-list {
  display: block !important;
}
.cc .mobile.header-carrousel {
  padding: 0 !important;
}
.cc .mobile.header-carrousel .slider {
  margin: 0;
}
.cc .mobile.header-carrousel .slider .banner {
  background: #fff !important;
}
.cc .mobile.header-carrousel .slider-list {
  bottom: -2px !important;
}
.cc .slider {
  overflow: hidden;
  /*px*/ margin: 20px 0 0 0;
  /*rem*/ margin: 20 / 16rem 0 / 16rem 0 / 16rem 0 / 16rem;
}
.cc .slider div[aria-hidden="true"] {
  display: block;
}
.cc .slider-inner div.actions {
  background: none !important;
}
.cc .slider-inner div {
  background: #fff;
  /*px*/ padding: 0 2px;
  /*rem*/ padding: 0 / 16rem 2 / 16rem;
}
.cc .slider-inner div p.paddings {
  /*px*/ padding: 2px 4px;
  /*rem*/ padding: 2 / 16rem 4 / 16rem;
}
.cc .slider-inner fieldset {
  border: none;
  margin: 0;
  /*px*/ padding: 20px;
  /*rem*/ padding: 20 / 16rem;
}
.cc .slider-inner legend {
  font-weight: bold;
  /*px*/ margin-top: 20px;
  /*rem*/ margin-top: 20 / 16rem;
}
.cc .slider-inner input[type=radio] {
  /*px*/ margin-right: 20px;
  /*rem*/ margin-right: 20 / 16rem;
}
.cc .slider-buttons {
  /*px*/ margin: 20px;
  /*rem*/ margin: 20 / 16rem;
  margin-top: -1px;
  /* border-top: 1px solid @C3;
				background: @C1; */
  /*px*/ padding: 0;
  /*rem*/ padding: 0 / 16rem;
  /*px*/ height: 33px;
  /*rem*/ height: 33 / 16rem;
  position: relative;
}
.cc .slider-buttons button {
  float: left;
  width: 50%;
  /*px*/ height: 40px;
  /*rem*/ height: 40 / 16rem;
  /*px*/ padding: 0;
  /*rem*/ padding: 0 / 16rem;
  border: none;
  background: none;
  color: #1683b5;
  text-align: right;
}
.cc .slider-buttons button.next {
  border-left: 1px dotted #1683b5;
  text-align: left;
}
.cc .slider-buttons button:before {
  font-family: fts_commun;
  /*px*/ font-size: 32px;
  /*rem*/ font-size: 32 / 16rem;
}
.cc .slider-buttons button.prev:before {
  content: "\e606";
}
.cc .slider-buttons button.next:before {
  content: "\e603";
}
.cc .slider-buttons button:hover,
.cc .slider-buttons button:focus {
  color: black;
}
.cc .slider-buttons span.pos {
  color: #1683b5;
  font-size: 0.8em;
  position: absolute;
  /*px*/ right: 4px;
  /*rem*/ right: 4 / 16rem;
  /*px*/ bottom: 6px;
  /*rem*/ bottom: 6 / 16rem;
}
.cc .slider-next {
  text-align: center;
  /*px*/ min-height: 33px;
  /*rem*/ min-height: 33 / 16rem;
  position: relative;
}
.cc .slider-next .button {
  background-color: #0d445f;
  border-color: #0d445f;
  color: #1683b5;
}
.cc .slider-next .button[disabled] {
  background-color: #0d445f;
  border-color: #0d445f;
  color: #273a7e;
}
.cc .slider-steps {
  position: relative;
  /*px*/ height: 13px;
  /*rem*/ height: 13 / 16rem;
  /*px*/ margin: 0 -4px;
  /*rem*/ margin: 0 / 16rem -4 / 16rem;
}
.cc .slider-steps progress {
  position: absolute;
  /*px*/ top: 13px;
  /*rem*/ top: 13 / 16rem;
  left: 0;
  width: 100%;
  /*px*/ height: 4px;
  /*rem*/ height: 4 / 16rem;
  -webkit-appearance: none;
  appearance: none;
  background-color: #1683b5;
  border: none;
}
.cc .slider-steps progress::-moz-progress-bar {
  background-color: #0d445f;
}
.cc .slider-steps progress::-webkit-progress-bar {
  background-color: #1683b5;
}
.cc .slider-steps progress::-webkit-progress-value {
  background-color: #0d445f;
}
.cc .slider-steps div.progress {
  background: #1683b5;
  height: 10px;
  position: relative;
  top: 10px;
}
.cc .slider-steps div.progress div {
  position: absolute;
  top: 0;
  left: 0;
  background: #0d445f;
  width: 0;
  height: 10px;
  z-index: 10;
}
.cc .mobile {
  padding: 26px !important;
}
.cc .mobile .slider-buttons {
  display: none;
}
.cc .mobile .slider-list {
  display: block;
  bottom: -14px;
}
.cc .desktop .slider-list {
  display: none;
}
.cc [data-slider-type=carousel] {
  position: relative;
}
.cc [data-slider-type=carousel].big-slider .slider {
  border: none;
}
.cc [data-slider-type=carousel] .slider-inner > div {
  float: left;
  /*px*/ padding: 2px 0;
  /*rem*/ padding: 2 / 16rem 0 / 16rem;
}
.cc [data-slider-type=carousel] .slider-list {
  position: absolute;
  border-width: 0;
  /*px*/ left: 30px;
  /*rem*/ left: 30 / 16rem;
  bottom: -16px;
  text-align: center;
  width: 80%;
  width: calc(100% - 60px);
  z-index: 10;
}
.cc [data-slider-type=carousel] .slider-list ol {
  list-style-type: none;
  margin: 0;
  z-index: 1;
  position: relative;
}
.cc [data-slider-type=carousel] .slider-list li {
  display: inline-block;
  /*px*/ margin: 0 5px;
  /*rem*/ margin: 0 / 16rem 5 / 16rem;
}
.cc [data-slider-type=carousel] .slider-list a {
  display: inline-block;
  background: white;
  border: 1px solid #273a7e;
  /*px*/ width: 10px;
  /*rem*/ width: 10 / 16rem;
  /*px*/ height: 10px;
  /*rem*/ height: 10 / 16rem;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  cursor: pointer;
}
.cc [data-slider-type=carousel] .slider-list a[aria-selected=true] {
  background-color: #0d445f;
}
.cc [data-slider-type=carousel] .slider-list a:hover {
  background-color: #273a7e;
}
.cc [data-slider-type=carousel] .slider-buttons button {
  float: none;
  width: auto;
  position: absolute;
  top: -140px;
  color: #0d445f;
}
.cc [data-slider-type=carousel] .slider-buttons button.prev {
  left: -60px;
  z-index: 10;
}
.cc [data-slider-type=carousel] .slider-buttons button.next {
  right: -45px;
  border-left: none;
  z-index: 10;
}
.cc [data-slider-type=carousel] .slider-buttons button.prev:before {
  content: "\e605";
}
.cc [data-slider-type=carousel] .slider-buttons button.prev:before:hover {
  color: black;
}
.cc [data-slider-type=carousel] .slider-buttons button.next:before {
  content: "\e636";
}
.cc [data-slider-type=carousel] .slider-buttons button.next:before:hover {
  color: black;
}
.cc [data-slider-type=carousel] .slider-buttons button:hover {
  color: black;
}
@media only screen and (max-width: 768px) {
  .slider {
    margin-left: 0;
    margin-right: 0;
  }
}
.cotechPageInformation {
  z-index: 5000;
  position: relative;
  padding: 15px 40px;
  border: 5px solid #619600;
  background: #fff;
}
.cotechPageInformation p,
.cotechPageInformation .analyseCode {
  padding: 5px 0;
  font-size: 1.2em;
}
.cotechPageInformation .analyseCode {
  display: flex;
  align-items: center;
}
.cotechPageInformation .analyseCode > strong {
  padding-right: 15px;
}
#unclosed-tag-loader span {
  padding-left: 15px;
}
#unclosed-tag-loader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.cotechError {
  background: #ff0000 !important;
}
img.cotechError {
  border: 5px solid #ff0000 !important;
}
table.cotechError {
  background: #fff !important;
  border: 5px solid #ff0000 !important;
}
.cotechTextError {
  background: #ff0000;
  color: #fff;
  font-weight: bold;
  padding: 5px;
}
.cotechErrorExplanation {
  color: #ff0000;
  font-weight: bold;
}
.cotechWarning {
  background: #dfb500 !important;
}
img.cotechWarning {
  border: 5px solid #dfb500 !important;
}
table.cotechWarning {
  background: #fff !important;
  border: 5px solid #dfb500 !important;
}
.cotechTextWarning {
  background: #dfb500;
  color: #fff;
  font-weight: bold;
  padding: 5px;
}
.cotechWarningExplanation {
  color: #dfb500;
  font-weight: bold;
}
.cotechExergue {
  background: #b027b4;
  color: #fff;
  font-weight: bold;
}
.cotechLang {
  border: 1px dotted #0000cd;
  outline: 1px dashed #5fba7d !important;
}
.cotechAbbr {
  background: #bee86f;
}
.cotechNbsp {
  background: #5fba7d;
}
#unclosed-tag-finder-code {
  ackground-color: #eee;
  overflow: auto;
  margin: 0 0 1em;
  padding: 0.5em 1em;
  max-height: 300px;
}
#unclosed-tag-finder-code .line-number {
  float: left;
  margin: 0 1em 0 -1em;
  border-right: 1px solid;
  text-align: right;
}
#unclosed-tag-finder-code span,
#unclosed-tag-finder-code .line-number {
  display: block;
}
#unclosed-tag-finder-results {
  padding-bottom: 10px;
}
#unclosed-tag-finder-code {
  border-top: 1px solid #ccc;
}
/*# sourceMappingURL=./styles.css.map */